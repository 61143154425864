import { graphql } from 'gatsby';
import React from 'react';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import Layout from '~/components/Layout';
import Text from '~/components/molecules/Text';
import FadeInWhenVisible from '~/components/FadeInWhenVisible'
import CustomSEO from '~/components/seo/CustomSEO';

export default function CareerItemPage({ data: { wpCareerItem } }) {
  const {title, careerItem: { careerItem },seo} = wpCareerItem;

  const {
    city,
    country,
    contactLength,
    hoursOfWork,
    proposedStartDate,
    salary,
    content,
  } = careerItem;
  
  return (
    <Layout>
      <Seo title={seo.title} />
      <CustomSEO seo={seo} />
      <section className="pt-28 pb-24 md:pt-36 md:pb-28 bg-sand">
        <div className="container">
          <h1 className="text-h1 mb-8" dangerouslySetInnerHTML={{ __html: title }} />
          <ul className="space-y-1">
            {city && (<li><span className="font-medium">Location: </span>{city}{country.length > 0 ? ', ' : ' '}{country}</li>)}
            {contactLength && <li><span className="font-medium">Contact length: </span>{contactLength}</li>}
            {hoursOfWork && <li><span className="font-medium">Hours of work: </span>{hoursOfWork}</li>}
            {proposedStartDate && <li><span className="font-medium">Proposed start date: </span>{proposedStartDate}</li>}
            {salary && <li><span className="font-medium">Salary: </span>{salary}</li>}
          </ul>
        </div>
      </section>
      <section id="content" className="my-20 md:my-32">
        <div className="container">
          <FadeInWhenVisible>
            <Text content={content} />
          </FadeInWhenVisible>
        </div>
      </section>
    </Layout>
  );
}

export const pageQuery = graphql`
  query CareerItemQuery($id: String!) {
    wpCareerItem(id: { eq: $id }) {
        seo{
          title
        }
      title
      careerItem {
        careerItem {
          city
          contactLength
          content
          country
          department
          fieldGroupName
          hoursOfWork
          proposedStartDate
          salary
        }
      }
    }
  }
`;
